import { ReactElement, useEffect, useState } from "react";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Box, Button, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";



function App() {

  let [ans, setAns] = useState<number>();
  let [showAns, setShowAns] = useState<boolean>(false);
  let [nth, setNth] = useState<number>(-1);
  let [nextNth, setNextNth] = useState<number>(0);
  let [parts, setParts] = useState<Array<number>>([]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const writePart = () => {
    if (nth === nextNth) {
      return null;
    }

    const numParts = Math.floor(Math.random() * 5) + 2;
    let newP = []
    for (let i = 0; i < numParts; i++) {
      newP.push(getRand())
    }
    setParts(newP)
    setAns(sumIntArray(newP))
    setNth(nextNth)

  }

  const newQ = () => {
    setShowAns(false)
    writePart()
    setNextNth(nextNth + 1)
  }

  useEffect(() => {
    newQ()
  }, [])

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.code === "ArrowRight") {
        newQ();
      }
      if (event.code === "ArrowDown") {
        setShowAns(true);
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  const sumIntArray = (arr: Array<number>) => {
    let sum = 0;

    for (const x of arr) {
      if (!Number.isNaN(x)) {
        sum += x;
      }
    }

    return Math.round(sum * 100) / 100;;
  }

  const getRand = () => {
    const x = (Math.floor(Math.random() * 1000) / 1000) * 10 + 1;

    return Math.round(x * 100) / 100;
  }

  const renderParts = () => {

    let out: Array<ReactElement> = []
    for (const x of parts) {
      out.push(<Typography fontSize={"2rem"}>{x}</Typography>)
      out.push(<Typography lineHeight={"3rem"}>+</Typography>)
    }
    out.pop()
    return out;
  }

  return (


    <Box sx={{ flexGrow: 1, margin: "10vw" }}>
      <Grid container spacing={2}>

        <Grid item xs={12} textAlign={"center"}>
          <Stack direction="row" spacing={2} justifyContent={"center"} sx={{ zoom: matches ? "0.7" : "1" }}>
            {renderParts()}
          </Stack>
        </Grid>

        <Grid item xs={12} textAlign={"center"}>
          <Typography fontSize={"1.5rem"} color={"green"}>
            {showAns ? ans : "-"}
          </Typography>

        </Grid>

        <Grid item xs={6} textAlign={"end"}>
          <Button variant="outlined" onClick={newQ}>new </Button>
        </Grid>
        <Grid item xs={6}>
          <Button variant="outlined" onClick={() => { setShowAns(true) }}>answer</Button>
        </Grid>
      </Grid>
    </Box >
  );
}

export default App;
